@import '~assets/sass/utils/helpers/index';
.lpa-radio-group {
  &.isError {
    label p {
      color: theme('colors.red') !important;
    }
    .btn-radio {
      border-color: theme('colors.red') !important;
    }
  }
}
