@import '~assets/sass/utils/helpers/index';
.HomeEntry-green-1 {
  color: theme('colors.globalFont');
}

.HomeEntry-green-2 {
  color: theme('colors.homeEntry');
}

.HomeEntry-grey-6 {
  color: theme('colors.grey.600');
}

.HomeEntry-title {
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 28px;
}

.HomeEntry-sub-title {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.HomeEntry-text {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.HomeEntry-paragrah-1 {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.26px;
  margin-right: 10px;
}
.HomeEntry-paragraph-2 {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bulle {
  margin-left: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  border: 1px solid rgba(210, 225, 224, 1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
