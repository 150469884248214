/*

FONT-WEIGHT
weight-(light/regular/medium/black)

 */

.weight {
  &-light {
    font-weight: $light;
  }
  &-medium {
    font-weight: $mediumW;
  }
  &-black {
    font-weight: $blackW;
  }
}
