@import '~assets/sass/utils/helpers/index';
.HomeCard {
  padding-block: 14px;
  padding-inline-start: 10px;
  padding-inline-end: 20px;

  img {
    filter: hue-rotate(theme('colors.hueRotate'));
  }
}
