/* FONTS */

//  FONT SIZES

$tiny: 1.2rem;
$small: 1.4rem;
$mediumS: 1.6rem;
$large: 2rem;
$veryLarge: 2.4rem;
$huge: 3rem;

// FONT SIZE MOBILE

$Mtiny: 1.2rem;
$Msmall: 1.4rem;
$MmediumS: 1.6rem;
$Mlarge: 2rem;
$MveryLarge: 2.5rem;
$Mhuge: 3rem;

// FONT STYLE

$light: 300;
$regular: 400;
$mediumW: 500;
$blackW: 900;

// COLOR VARIABLES
$colors: (
  primary: (
    primary: #0F4C36,
    light: #008851,
    dark: #005533
  ),
  secondary: (
    primary: #fff101,
    light: #fff99a,
    dark: #e6da00
  ),
  tertiary: (
    primary: #d4df4c,
    light: #dee777,
    dark: #c5d226
  ),
  camo-green: (
    primary: #563f23,
    light: #7a5a32,
    dark: #322414
  ),
  squash: (
    primary: #eba506,
    light: #faba2a,
    dark: #b98205
  ),
  light-mustard: (
    primary: #f3c96a,
    light: #f7da99,
    dark: #efb83b
  ),
  deep-orange: (
    primary: #e54800,
    light: #ff6119,
    dark: #b23800
  ),
  pale-grey: (
    primary: #f6f7fb,
    dark: #d3d8eb
  ),
  grey-2: (
    primary: #e6e6e6,
    dark: #cccccc
  ),
  brown-grey: (
    primary: #8f8f8f,
    light: #a8a8a8,
    dark: #757575
  ),
  brownish-grey: (
    primary: #787878,
    light: #919191,
    dark: #5e5e5e
  ),
  greyish-brown: (
    primary: #3d3d3d,
    light: #565656,
    dark: #232323
  ),
  green-STATUS: (
    primary: #79cc26,
    light: #92dd48,
    dark: #5fa11e
  ),
  red-STATUS: (
    primary: #f04242,
    light: #f47171,
    dark: #ec1313
  ),
  moss-green: (
    primary: #668e3c
  ),
  carmine: (
    primary: #93070a
  )
);
$white: #ffffff;
$black: #000000;

// Colors
$red: #EE513C;
$orange: #EE9C3C;
$yellow: #FFCC69;
$green0: #0B3526;
$green1: #0F4C36;
$green2: #2E6652;
$green3: #318869;
$grey600: #769B8E;
$grey500: #8BA59C;
$grey400: #B7C6C5;
$grey300: #D2E1E0;
$grey200: #EEF8F4;
$grey100: #EEF1F3;
$grey000: #F7F8FB;
$white_font: #E4EDEC;

// Widths
$minXs: '0px';
$minSm: '480px';
$minMd: '834px';
$minLg: '1024px';
$minXl: '1440px';

$maxXs: '479px';
$maxSm: '833px';
$maxMd: '1023px';
$maxLg: '1439px';

// Font
// Families
$font_primary: 'Inter', sans-serif !important;

// Sizes
$h1_desktop_size: 28px;
$info_level0_desktop_size: 18px;
$h2_desktop_size: 16px;
$paragraphe_level1_desktop_size: 14px;
$h4_size: 13px;
$subtitle_desktop_size: 11px;

$h1_mobile_size: 22px;
$info_level0_mobile_size: 14px;
$h2_mobile_size: 14px;
$paragraphe_level1_mobile_size: 13px;
$subtitle_mobile_size: 9px;

// Weights
$extrabold: 800;
$bold: 700;
$semibold: 600;
$medium: 500;
$regular: 400;
$light: 300;
$thin: 200;
