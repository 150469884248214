:root {
    font-family: $font_primary;
    box-sizing: border-box !important;
    font-size: 62.5%;
}

* {
    line-height: 1.2;
}

html, body {
    font-family: $font_primary;
    height: 100%;
    font-size: 1.6rem;
}

a {
    color: unset;
    text-decoration: none;

    &:hover, &:focus, &:active {
        text-decoration: unset;
        font-weight: unset;
    }
}
