@import '~assets/sass/utils/helpers/index';
.loaderContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  &.fullScreenLoader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #ffffff8a;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1000;
    text-align: center;
    justify-content: center;
  }

  &.topMargin {
    margin-top: 180px;
  }
}
