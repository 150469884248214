@import '~assets/sass/utils/helpers/index';
.ContactCard{
        @apply
        bg-white
        rounded
        flex
        flex-col;
        border-radius: 4px;
        border: 1px solid rgb(210 225 224);
        box-shadow: 0px 2px 4px 0px rgba(8, 36, 39, 0.15);
        font-size: 13px;
        line-height: 16px;
        font-weight: theme('fontWeight.500');
        letter-spacing: 0.02em;

  &-container {
    @apply grid grid-cols-1 md:grid-cols-2 gap-1.5 md:gap-x-9 gap-y-4;
  }

        &-row{
            @apply
            border-grey-300;

            align-items: center;
            padding:30px;
            border-bottom: 1px solid;

        &:last-child {
        border-bottom: 0;
        }
       }
}

.ContactCard-text {
    @apply
    mb-2;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ContactCard-paragraph-1 {
    @apply
    mb-4
    uppercase;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.26px;
}

.ContactCard-paragraph-2 {
    @apply
    mb-4;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ContactCard-green-1 {
    color: theme('colors.globalFont');
}

.ContactCard-green-2 {
    color: theme('colors.green.500');
}

.ContactCard-grey-6{
    color: theme('colors.grey.600');
}


.ContactCard {
  &-info {
    display: flex;
    align-items: center;
    gap: 14px;

    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-infoLink {
    display: flex;
    align-items: center;

    img {
      filter: hue-rotate(theme('colors.hueRotate'));
    }
  }
}
