@import '~assets/sass/utils/helpers/index';
@import '~assets/sass/utils/styleSheetVariables';

.containerFormsInput {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  label.icon {
    padding: 16px 12px;
    border-left: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-top: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-bottom: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-right: 0px;
    color: map_get(map_get($colors, 'grey-2'), 'primary');
    border-radius: 5px 0px 0px 5px;
    display: block;
    height: 48px;
    background-color: white;
  }
}
