.Card {
  @apply lpa-shadow-large
  bg-white
  rounded
  flex
  flex-col;
  border: 1px solid rgb(210 225 224);
  font-size: 13px;
  line-height: 16px;
  font-weight: theme('fontWeight.500');
  letter-spacing: 0.02em;
  overflow: hidden;

  --column-gap: 5px;
  --min-column-width: min(calc(25% - var(--column-gap) * 3), 260px);

  @screen lg {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.26px;
  }

  &-rowScrollWrapper {
    &.isScroller {
      max-height: 500px;
      overflow-y: auto;
    }

    &.isLocked {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(to bottom, transparent 70%, theme('colors.grey.300'));
        pointer-events: none;
      }
    }
  }

  &-row {
    &.isMainRow {
      @apply px-4
      py-5
      lg:px-5
      lg:py-6;

      border-bottom: 1px solid;
      @apply border-grey-300;

      &:last-child {
        border-bottom: 0;
      }
    }

    &.isSecondaryRow {
      @apply px-4
      py-5
      lg:px-5
      lg:py-6;
      background: theme('colors.grey.100');

      border-bottom: 1px solid;
      @apply border-grey-300;

      &:last-child {
        border-bottom: 0;
      }
    }

    &.isSubRow {
      @apply px-4
      py-5
      lg:px-5
      lg:py-6;

      background: theme('colors.grey.000');
      border-block: solid theme('colors.grey.300') 1px;
      border-block-start: 0;

      &:last-child {
        border-block-end: 0;
      }

      // When two subRow follow each other
      & + & {
        border-block: solid theme('colors.grey.300') 1px;
      }
    }

    &.isSubPreviewRow {
      @apply px-4
      py-5
      lg:px-5
      lg:py-6;

      background: theme('colors.grey.100');
      border-block: solid theme('colors.grey.300') 1px;
      border-block-start: 0;

      &:last-child {
        border-block-end: 0;
      }
    }

    &.isShowMore {
      @apply px-4 py-4;
      background: theme('colors.grey.100');
      transition-duration: 0.3s;
      border-block-start: solid theme('colors.grey.300') 1px;
    }
  }

  &-tags {
    @apply flex
    gap-1.5;
  }

  &-infos {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 5px;
    width: 100%;

    @include max($maxMd) {
      flex-direction: column;
    }
  }

  &-infoGroup {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    min-width: var(--min-column-width);
  }

  &-info {
    @screen lg {
      min-width: var(--min-column-width);
    }

    &.isQuantity {
      display: flex;

      .Card-value {
        @include max($maxMd) {
          font-variant-numeric: tabular-nums;
          margin-left: auto;
        }
      }
    }

    &.hasProgressbar {
      display: flex;
      flex-direction: column;

      @include max($maxMd) {
        row-gap: 8px;
      }

      @screen lg {
        flex-direction: row;
      }

      .Card-label {
        flex-shrink: 0;

        @include max($maxMd) {
          margin: 0;
        }
      }

      .Progressbar {
        @screen lg {
          margin-block-start: -0.2em;
        }
      }
    }

    &.forceEndOfLine {
      @screen lg {
        display: flex;
        margin-inline-start: auto;
        justify-content: flex-end;
        margin-right: 0;
      }
    }

    &.isImportant {
      .Card-value {
        font-size: 13px;
        @screen lg {
          font-size: 16px;
        }
      }
    }

    &.isDoubleWidth {
      @screen lg {
        min-width: calc(2 * var(--min-column-width) + var(--column-gap));
      }
    }

    &.onSeparateLines {
      .Card-label {
        display: block;
      }
    }

    &.fillLine {
      flex-grow: 1;
    }

    &.isReturn {
      .Card-label,
      .Card-value {
        color: theme('colors.grey.600') !important;
      }
    }
  }

  &-label {
    @apply text-grey-500;
    margin-inline-end: 0.4em;

    &-reversed {
      color: theme('colors.globalFont');
      font-size: 16px;
      font-weight: 600;
    }
  }

  &-value {
    font-size: 13px;
    color: theme('colors.globalFont');
  }

  &-innerSeparator {
    width: 100%;
    margin-block: 16px;
  }

  &-showMoreContent {
    display: flex;
    align-items: center;
    justify-content: center;
    color: theme('colors.globalFont');
    cursor: pointer;
    gap: 6px;
  }
}

.CardDocument {
  @apply flex
  max-lg:flex-col
  lg:items-center
  lg:justify-between
  gap-1;

  &-titleGroup {
    color: theme('colors.globalFont');
    display: flex;
    align-items: center;
    column-gap: 8px;

    &.isDownloadable {
      @apply cursor-pointer;
    }

    img {
      filter: hue-rotate(theme('colors.hueRotate'));
    }

    &.blackAndWhiteIcon img {
      filter: grayscale(1);
    }
  }

  &-title {
    font-size: 14px;
    line-height: 1.2em;
    font-weight: theme('fontWeight.700');
    letter-spacing: 0.02em;

    @screen lg {
      font-size: 18px;
      font-weight: theme('fontWeight.600');
    }
  }

  &-subTitle {
    font-size: 13px;
    line-height: 1.2em;
    font-weight: theme('fontWeight.500');
    letter-spacing: 0.02em;
    color: theme('colors.grey.500');
  }
}

.CardBrand {
  &-name {
    font-size: 13px;
    line-height: 1.2;
    font-weight: theme('fontWeight.600');

    @screen lg {
      font-size: 16px;
    }

    color: theme('colors.globalFont');
  }

  &-subname {
    @apply text-grey-500
    ml-1;
  }
}

.ContractContent {
  @screen lg {
    display: flex;
    width: auto;
  }

  @include max($maxMd) {
    .CardBrand {
      margin-bottom: 20px;
    }
  }

  .Card-tags {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

.DeliveryContent {
  @screen lg {
    display: flex;

    .Card-infos {
      margin-left: auto;
    }
  }
  .CardBrand-name {
    @apply mb-5
    lg:mb-0
    uppercase;
  }
}

.DeliveryNorme {
  &-title {
    @apply mb-4;
    color: theme('colors.globalFont');
    font-size: 13px;
    line-height: 1.2;
    font-weight: theme('fontWeight.600');

    @screen lg {
      font-size: 16px;
    }
  }

  &-content {
    @apply grid
    grid-cols-2
    lg:flex
    lg:flex-row
    lg:flex-wrap
    gap-y-1;
  }

  &-item {
    margin-bottom: 5px;
    margin-right: 5px;
    @screen lg {
      margin-right: 30px;
    }
  }
}

.DeliverySummary {
  @apply px-4 py-5 bg-grey-100 shadow-md mb-7;
  font-size: 13px;

  @screen lg {
    display: flex;
    gap: 10px;
    column-gap: 50px;
    flex-wrap: wrap;
    font-size: 16px;
  }

  &-break {
    flex: 1 1 100%;
  }

  &-item {
    display: flex;
    align-items: center;

    @screen lg {
      min-width: 250px;
    }

    @include max($maxMd) {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(2) {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid theme('colors.gray.300');
      }
    }
  }

  &-label {
    @apply text-grey-600;
    margin-inline-end: 0.4em;

    @screen lg {
      order: -1;
    }
  }

  &-value {
    color: theme('colors.globalFont');

    font-weight: theme('fontWeight.700');

    @screen lg {
      order: -1;
      margin-right: 10px;
    }

    @include max($maxMd) {
      margin-left: auto;
    }

    &.isNull {
      font-weight: theme('fontWeight.500');
    }
  }
}
