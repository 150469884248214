@import '~assets/sass/utils/helpers/index';
.AccountingListMobile {
  display: grid;
  gap: 14px;

  &-card {

    .CardDocument-title {
      margin-top: 2px;
      font-size: 1.2rem;
    }

    .CardDocument-subTitle {
      font-size: 1.1rem;
    }

    & img {
      margin-right: 5px;
      filter: hue-rotate(theme('colors.hueRotate'));
    }

    .CardDocument-titleGroup-right {
      margin-left: auto;
      white-space: nowrap;

      &-type {
        color: $grey600;
        font-size: 1.1rem;
      }

      &-total {
        font-weight: $bold;
        font-size: 1.2rem;
      }
    }

    .Card-label, .Card-value {
      font-size: 1.2rem !important;

      &-total{
        font-weight: $bold;
      }
    }

    & .isShowMore {
      & .text-interactif {
        font-size: 1.2rem !important;
      }
    }

    &-linkedDocument {
      background-color: $grey000 !important;

      &-echeance {
        color: $grey600;
        font-size: 1.2rem;
        padding: 0 !important;
        margin-top: 8px;
      }
    }
  }
}
