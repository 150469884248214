@import '~assets/sass/utils/helpers/index';
.OptionModal {
  .rodal {
    z-index: 150 !important;
  }

  .rodal-dialog {
    padding: 0;
    background: transparent;
  }
}
