.OdvModal .rodal-dialog {
    padding: 0 0 1.375rem 0;
    overflow: hidden;
}

.OdvModal .modal-header {
    height: 30px;
    padding: 0 1.375rem 0 1.375rem;
    background-color: #006f42;
    color:white;
}

.OdvModal form {
    padding: 1.875rem;
}

.OdvModal input[type="checkbox"]:checked {
    filter: hue-rotate(290deg) saturate(60%);
}

/* .OdvModal .forms {
    background-color: white;
    filter: hue-rotate(290deg) saturate(60%);
} */

.OdvModal .forms-numbers {
    padding: 1rem 1.75rem 1rem 0.875rem;
}

.OdvModal .forms-numbersArrows {
    width: 2.5rem;
}

.OdvModal .css-tlfecz-indicatorContainer {
    padding: 0;
}

