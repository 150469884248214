@import '~assets/sass/utils/helpers/index';
.file-list {
    display : flex;
    flex-wrap: wrap;
    margin: 0 0 40px 0;

    >div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 10px;

        >div {
            display: flex;
            flex: 1;
            justify-content: center;
            align-content: center;
        }
    }

    a {
        display: block;
    }


}

.offers-container {
    display: block;
    text-align: left;
    padding: 10px;
    width: 100%;

    p {
        margin: 0 0 20px 0;
    }
}
