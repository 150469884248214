@import '~assets/sass/utils/helpers/index';
.lpa-select {
  --input-padding-block: 9px;
  --input-padding-inline: 18px;

  position: relative;

  font-size: 13px;
  line-height: 17px;
  font-weight: theme('fontWeight.semibold');

  &__option--is-disabled {
    color: #AAA;
  }

  &__control {
    border-radius: 4px;
    border: 1px solid theme('colors.grey.400');
    padding-inline: var(--input-padding-inline);
    padding-block: calc(var(--input-padding-block) - 4px);
    background: theme('colors.white');
    width: 100%;
    min-width: 100px;

    &:active,
    &:focus {
      border-color: theme('colors.grey.600');
    }
  }

  &__value-container--is-multi {
    @apply gap-1;

    // Remove gap between items and input element when select is not focus
    & > *:last-child {
      margin-inline-start: -4px;
    }
  }

  &__placeholder {
    color: theme('colors.grey.500');
    margin-block: 4px;
  }

  &__single-value {
    color: theme('colors.globalFont');
  }

  &__multi-value {
    @apply bg-green-200 text-white rounded;
  }

  &__multi-value__label {
    @apply p-1;
  }

  &__multi-value__remove {
    @apply p-1 hover:bg-green-100;
  }

  &__dropdown-indicator {
    color: theme('colors.globalFont');
  }

  &__menu {
    background: theme('colors.white');
  }

  &__menu-list {
    @apply py-2 shadow;
    border: 1px solid theme('colors.grey.400');
    border-radius: 4px;
    transform: translateY(2px);
  }

  &__option {
    @apply py-2 px-4 cursor-pointer;

    &--is-focused {
      @apply bg-grey-000;
    }

    &--is-selected {
      @apply bg-green-100 text-white;
    }
  }

  &__indicators {
    @apply gap-0.5;
    margin-inline-start: 4px;
  }

  &__clear-indicator {
    @apply bg-green-200 cursor-pointer p-0.5 rounded-full text-white hover:bg-green-100;

    svg {
      @apply w-3 h-3;
    }
  }

  &__input-container {
    // Hide input element when select is not focused
    width: 0 !important;
    position: absolute !important;
  }

  &.isFocused {
    .lpa-select {
      &__control {
        border-color: theme('colors.grey.600');
      }

      // Rollback specific override
      &__input-container {
        width: auto !important;
        position: unset !important;
      }

      // Rollback specific override
      &__value-container--is-multi > *:last-child {
        margin-inline-start: 0;
      }
    }
  }

  &.isError {
    .lpa-select {
      &__control {
        border-color: theme('colors.red');
      }
    }
  }
}
