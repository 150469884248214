@import '~assets/sass/utils/helpers/index';
.StatusTag {
  display: flex;
  align-content: center;
  justify-content: center;
  @apply border;
  @apply border-transparent;

  width: max-content;
  border-radius: 2px;
  padding: 6px 8px;

  font-size: 12px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  font-weight: theme('fontWeight.600');
  color: theme('colors.white');
  background-color: #444444;

  &.isLower {
    text-transform: initial;
  }

  &-icon {
    margin-right: 3px;
  }

  &.themeGreen {
    background-color: theme('colors.sharedGreen.100');
    color: theme('colors.white');
  }

  &.themeYellow {
    background-color: theme('colors.sharedYellow');
    color: theme('colors.sharedGreen.100');
  }

  &.themeGrey {
    background-color: theme('colors.sharedGrey.400');
    color: theme('colors.sharedGreen.300');
  }
}
