@layer utilities {
  .page-title {
    @apply flex items-start w-full flex-shrink-0 font-medium pb-3 pt-2;
    font-size: 2.75rem;
  }

  .text-page-title {
    @apply font-700;
  }

  .upside-down {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  }

  .bg-green-gradient-100 {
    background: linear-gradient(180deg, theme('colors.greenGradient.000') -157.77%, theme('colors.greenGradient.100') 109.22%);
  }

  .bg-green-gradient-200 {
    background: linear-gradient(180deg, theme('colors.greenGradient.200') 40%, theme('colors.greenGradient.300') 100%);
  }

  .text-title-1 {
    font-size: 22px;
    line-height: 26px;
    font-weight: theme('fontWeight.700');

    @screen lg {
      font-size: 28px;
      line-height: 32px;
      font-weight: theme('fontWeight.700');
    }
  }

  .text-title-2 {
    font-size: 14px;
    font-weight: theme('fontWeight.700');
    line-height: 17px;

    @screen lg {
      font-size: 16px;
      line-height: 19.36px;
      font-weight: theme('fontWeight.700');
    }
  }

  .text-title-3 {
    font-size: 12px;
    font-weight: theme('fontWeight.700');
    line-height: 17px;

    @screen lg {
      font-size: 16px;
      line-height: 19.36px;
      font-weight: theme('fontWeight.700');
    }
  }

  .text-title-4 {
    font-size: 13px;
    font-weight: theme('fontWeight.700');
    line-height: 16px;
    letter-spacing: 0.02em;

    @screen lg {
      font-size: 13px;
      line-height: 15.73px;
      letter-spacing: 0.02em;
      font-weight: theme('fontWeight.700');
    }
  }

  .text-sub-title {
    font-size: 9px;
    line-height: 13px;
    font-weight: theme('fontWeight.500');
    letter-spacing: 0.02em;

    @screen lg {
      font-size: 11px;
      line-height: 13px;
      font-weight: theme('fontWeight.500');
      letter-spacing: 0.02em;
    }
  }

  .text-paragraph-1 {
    font-size: 13px;
    line-height: 16px;
    font-weight: theme('fontWeight.500');
    letter-spacing: 0.02em;

    @screen lg {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.28px;
    }
  }

  .text-paragraph-2 {
    font-size: 13px;
    line-height: 16px;
    font-weight: theme('fontWeight.500');
    letter-spacing: 0.02em;

    @screen lg {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.26px;
    }
  }

  .text-info-0 {
    font-size: 14px;
    line-height: 1.2em;
    font-weight: theme('fontWeight.700');
    letter-spacing: 0.02em;

    @screen lg {
      font-size: 18px;
      line-height: 1.2em;
      letter-spacing: 0.02em;
      font-weight: theme('fontWeight.600');
    }
  }

  .text-info-1 {
    font-size: 13px;
    line-height: 1.2em;
    font-weight: theme('fontWeight.700');

    @screen lg {
      font-size: 16px;
      line-height: 1.2em;
      font-weight: theme('fontWeight.600');
    }
  }

  .text-info-2 {
    font-size: 13px;
    line-height: 1.2em;
    font-weight: theme('fontWeight.500');
    letter-spacing: 0.02em;

    @screen lg {
      font-size: 13px;
      line-height: 1.2em;
      font-weight: theme('fontWeight.500');
    }
  }

  .text-interactif {
    font-size: 15px;
    font-weight: theme('fontWeight.600');
    line-height: 13px;
    color: theme('colors.darkGreen');

    @screen lg {
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.02em;
      font-weight: theme('fontWeight.500');
    }
  }

  .text-interactif-off {
    font-size: 11px;
    line-height: 15px;
    font-weight: theme('fontWeight.600');
    color: theme('colors.green.500');

    @screen lg {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .text-quotation{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.28px;
  }

  .text-quotation-zone{
    font-family: Inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.44px;
  }

  .text-quotation-qualite{
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.36px;
  }

  /*.lpa-shadow {
    box-shadow: 0px 2px 4px 0px rgba(8, 36, 39, 0.15);
  }*/

  .hr {
    @apply border-t border-grey-300;
  }

  .hr-m {
    @apply border-t border-grey-300 my-6;
  }
}
