@import '~assets/sass/utils/helpers/index';
@import '~assets/sass/utils/styleSheetVariables';

.arrowBtn {
  font-size: x-large;
  list-style: none;
}

.arrow-grey {
  background-color: #e6e6e6;
  color: white;

  &:hover {
    box-shadow: none;
    color: white;
    cursor: default;
  }
}

.pageIndexButton {
  color: map_get(map_get($colors, 'brownish-grey'), 'primary');
  padding: 1rem;
  cursor: pointer;

  &:hover {
    color: map_get(map_get($colors, 'greyish-brown'), 'primary');
    font-weight: 600;
  }

  &.active {
    color: map_get(map_get($colors, 'primary'), 'primary');
    font-weight: 600;
    cursor: default;

    &:hover {
      color: map_get(map_get($colors, 'primary'), 'primary');
    }
  }

  &.hidden-interval {
    cursor: default;

    &:hover {
      font-weight: normal;
      color: map_get(map_get($colors, 'brownish-grey'), 'primary');
    }
  }
}
