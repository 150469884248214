// custom theme extended from 'tippy.js/dist/tippy.css'
.tippy-box[data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  position: relative;
  background-color: #ffffff;
  color: theme('colors.globalFont');
  border-radius: 4px;
  border: solid theme('colors.grey.300') 1px;
  font-size: 14px;
  line-height: 18px;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
  box-shadow: 0px 2px 4px 0px #08242726;
}
.tippy-content {
  position: relative;
  padding: 10px;
  z-index: 1;

  @screen lg {
    padding: 20px;
  }
}
