@import '~assets/sass/utils/helpers/index';
.ProfileCard-green-1 {
  color: theme('colors.globalFont');
}

.ProfileCard-green-2 {
  color: theme('colors.green.500');
}

.ProfileCard-grey-6 {
  color: theme('colors.grey.600');
}

.ProfileCard {
  &-title {
    font-size: 14px;
    font-family: Inter;
    font-weight: 700;
    margin-bottom: 30px;

    @screen md {
      font-size: 16px;
    }
    @screen lg {
      font-size: 20px;
    }
  }
}

.Profile_valueTitle {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.28px;
  margin-right: 10px;
  min-width:120px;


  @screen md {
    min-width: 0;
  }

  @media (max-width: 390px) {
    margin-right: 10px;
  }
}

.Profile_value {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
  margin-right: 10px;
  overflow-wrap: anywhere;

  @screen md {
    margin-right: 0px;
    font-size: 14px;
  }
}

.value_row {
  @apply flex
    flex-col;

    @media (min-width: 834px) {
      @apply
      flex-row
      flex-wrap;
    }
}

.value_ItemsRow {
  @apply flex;
  align-items: baseline;
  margin-right: 30px;

  @media (max-width: 833px) {
    margin-bottom: 14px;
    margin-right: 0px;
  }
  @media (min-width: 834px) {
    margin-bottom: 0px;
  }
  @media (min-width: 860px) {
    margin-right: 50px;
  }
}

.lines {
  width: 100%;
  height: 1px;
  background-color: theme('colors.grey.300');
  margin-top: 30px;
  margin-bottom: 30px;
}

.ContactInfo {
  display: flex;
  flex-direction: column;
}

@media (max-width: 833px) {
  .ContactInfo {
    flex-direction: column;
  }

  .contactezMoi {
    width: 100%;
  }
}

.contactMe {
  @media (max-width: 833px) {
    @apply w-full
        justify-center;
  }
}
