@import '~assets/sass/utils/helpers/index';
.RepresentativeList {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;

  &-interlocuteurSubTitle {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: theme('colors.grey.600');
    margin-bottom: 20px;

    @screen md {
      font-size: 16px;
    }
  }
}
