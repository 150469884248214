@import '~assets/sass/utils/helpers/index';
.adivalorMobileTitle{
    @media(max-width:484px) {
        font-size:16px;
        font-weight:600;
        color: theme('colors.green.100');
        margin-bottom: 8px;
    }
}

.adivalorMobileVaues{
    @media(max-width:484px) {
        font-size:13px;
        color: theme('colors.green.100');
    }
}

.filterCard{
    @apply
    grid
    grid-cols-1
    lg:grid-cols-3
    gap-y-4
    py-5
    px-3.5
    lg:py-6
    lg:pl-4
    lg:pr-[60px]
    mb-7;
}

.filteredValue{
    @apply
    flex-grow
    flex
    items-center
    lg:mr-[30px];
}

.filteredRow{
@apply
flex
items-center
min-w-[110px]
lg:min-w-min
lg:mr-2.5
text-globalFont;
}
