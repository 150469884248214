@import '~assets/sass/utils/helpers/index';
@import '~assets/sass/utils/styleSheetVariables';

:root {
  --footer-height: 38px;
}

.sidebarWrapper {
  background-color: $white;
  box-shadow: 0px 2px 4px 0px #08242726;
  width: 100%;
  max-width: 307px;
  height: 100vh;
  box-shadow: 0px 2px 4px 0px #08242726;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;

  @extend %animateAll;
  transition-delay: 0.3s;

  @media (min-width: $minSm) {
    max-width: 323px;
  }

  @media (min-width: $minXl) {
    position: sticky;
    inset-block-start: 0;
  }

  &.collapsed {
    ~ .sidebarNav-mobileMask {
      opacity: 0;
      pointer-events: none;
    }

    @media (max-width: $maxLg) {
      left: -100%;
      box-shadow: none;
    }
  }

  &:not(.collapsed) {
    .sidebarNav-itemsList-item {
      @extend %animatedForward;
    }
  }

  &.collapsed .sidebarNav-closeButton {
    display: none;
  }
}

.sidebarNavWrapper {
  width: 100%;
  height: 100vh;
  background-color: $white;

  @media (min-width: $minXl) {
    height: calc(100vh - 38px);
    position: sticky;
    inset-block-start: 0;
  }
}

.sidebarNav {
  padding: 113px 20px 50px 20px;
  overflow-y: scroll;
  background-color: $white;
  color: theme('colors.grey.600');
  font-weight: $bold;
  font-size: $h4_size;
  @extend %animateAll;
  transition-delay: 0.3s;
  height: 100%;

  @media (min-width: $minXl) {
    height: calc(100% + 38px);
  }

  @media (min-width: $minXl) {
    height: 100%;
    flex: 1 0 auto;
    padding: 60px 30px;
    overflow: hidden;
  }

  .w-full {
    border-width: 1px;
    border-style: solid;
    border-radius: 25px;
    border-color: transparent;

    &:hover {
      background-color: theme('colors.grey.000');
      border-color: theme('colors.grey.300');
    }

    &.subPathsOpened {
      background-color: theme('colors.grey.000');
      border-color: theme('colors.grey.300');

      .sidebarNav-itemsList-item {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .opener {
      margin-left: 7px;
    }

    .subPathsLevel1 {
      border-radius: 25px;
      font-weight: $medium;
      padding: 0 0 15px 58px;
      background-color: theme('colors.grey.000');

      .active {
        color: theme('colors.green.100');
      }
    }
  }

  &-closeButton {
    position: absolute;
    top: 21px;
    left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 20px;

    .bar-1,
    .bar-2 {
      background-color: theme('colors.green.100');
      height: 1.5px;
      width: 100%;
    }

    .bar-1 {
      transform: translateY(calc(10px - 50%)) rotate(45deg);
    }

    .bar-2 {
      transform: translateY(calc(-10px + 50%)) rotate(-45deg);
    }
  }

  &-itemsList {
    @apply gap-4.5;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-subPathItem {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 6px 0 6px 20px;
      border-left: 1px solid theme('colors.grey.300');

      i {
        font-size: $veryLarge;
        opacity: 0.25;
        margin-right: 17px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-color: transparent;
      border-radius: 25px;
      border-width: 1px;
      border-style: solid;
      padding: 9px 0 9px 15px;
      gap: 5px;

      i {
        opacity: 0.25;
        font-size: 3.5rem;
        margin-right: 10px;
      }

      .navIcon {
        opacity: 0.25;
      }

      &:hover {
        background-color: theme('colors.grey.000');

        & i {
          opacity: 1;
        }

        .navIcon {
          opacity: 1;
        }
      }

      &.active {
        color: theme('colors.green.100');
        background-color: theme('colors.grey.000');
        border-color: theme('colors.grey.300');

        & i {
          opacity: 1;
        }

        .navIcon {
          opacity: 1;
        }
      }

      img {
        filter: hue-rotate(theme('colors.hueRotate'));
      }
    }
  }

  &-mobileMask {
    display: none;
    @extend %animateAll;

    @media (max-width: $maxLg) {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100%;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      top: 0;
    }
  }
}
