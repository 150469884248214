@import '~assets/sass/utils/helpers/index';
body.lepicard .Accounting {
  --tabs-active-color: theme('colors.greenLepicard');
  --tabs-active-background-color: #ffffff;
  --tabs-inactive-color: theme('colors.grey.450');
  --tabs-inactive-background-color: theme('colors.green.100');
}

body.cauchoise .Accounting {
  --tabs-active-color: theme('colors.greenLepicard');
  --tabs-active-background-color: #ffffff;
  --tabs-inactive-color: #ffffff;
  --tabs-inactive-background-color: #999eab;
}

body.luneray .Accounting {
  --tabs-active-color: theme('colors.greenLepicard');
  --tabs-active-background-color: #ffffff;
  --tabs-inactive-color: #ffffff;
  --tabs-inactive-background-color: #d8b79b;
}

.Accounting {
  flex: 1 1 0% !important;
  width: 100%;

  &-head {
    padding-top: 1.875rem;
    margin-bottom: 2rem;
    position: relative;
    margin-top: -2.8rem;

    @screen lg {
      padding-top: 4rem;
      margin-top: -2.8rem;
      margin-bottom: 3rem;
    }

    &-bg {
      position: absolute;
      inset: 0;
      bottom: -75px;
      margin-left: -1.6rem;
      margin-right: -1.6rem;

      @screen lg {
        margin-left: -2.8rem;
        margin-right: -2.8rem;
      }
    }

    &-tabList {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
      background: var(--tabs-inactive-background-color);
      border-radius: 4px;
      position: relative;
      color: var(--tabs-inactive-color);
      font-weight: $bold;
      text-transform: uppercase;

      @screen lg {
        margin-bottom: 50px;
      }

      li {
        border-radius: 4px;
        bottom: 0;

        &:hover {
          background-color: theme('colors.green.000');
        }

        &.react-tabs__tab--selected {
          background: var(--tabs-active-background-color) !important;
          color: var(--tabs-active-color);
        }
      }

      li {
        flex: 1 0 20%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        cursor: pointer;
        font-size: 12px;
        background-color: transparent;
        width: auto;
        white-space: nowrap;
        overflow: hidden !important;
        border-color: transparent !important;

        @screen lg {
          padding: 16px;
          font-size: 16px;
        }
      }

      // Overriding react-tabs css
      .react-tabs__tab:focus:after {
        background: transparent !important;
      }
    }

    &-title {
      position: relative;
      color: theme('colors.pageHeadText');

      h1 {
        margin-bottom: 0.875rem;
        text-transform: uppercase;
      }
    }
  }
}
