@import '~assets/sass/utils/helpers/index';
.lpa-checkbox {
  .lpa-checkbox-box {
    min-width: 28px;
    min-height: 28px;
  }

  .lpa-checkbox-box input {
    min-width: 28px;
    min-height: 28px;
  }

  &.isError {
    .lpa-checkbox-box {
      border-color: theme('colors.red') !important;
    }

    .lpa-checkbox-label {
      color: theme('colors.red') !important;
    }
  }
}
