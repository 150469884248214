@import '~assets/sass/utils/helpers/index';
@import '~assets/sass/utils/styleSheetVariables';

/* LOGIN FORMS */
/*doc
---
title: 1. Login step 1
name: 1. Login step 1
category: Login Forms
---
```html_example
  <div class="loginScreen-accountsContainer mt-15">
      <ul class="loginScreen-accounts">
          <li class="loginScreen-account">
              <div class="loginScreen-accountInfos">
                  <span class="loginScreen-accountAvatar red">LD</span>
                  <div class="loginScreen-accountCredentials">
                      <p class="size-medium weight-medium fc-black">Lou Duret</p>
                      <p class="size-medium weight-light fc-black">Exploitation ABC</p>
                      <p class="size-small weight-light fc-brownish-grey-primary">Identifiant 12345</p>
                  </div>
              </div>
              <div class="loginScreen-accountSelect">
                  <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
                  <p class="size-medium weight-medium fc-secondary-primary">Sélectionner</p>
              </div>
          </li>
          <li class="loginScreen-account">
              <div class="loginScreen-accountInfos">
                  <span class="loginScreen-accountAvatar green">LD</span>
                  <div class="loginScreen-accountCredentials">
                      <p class="size-medium weight-medium fc-black">Lou Duret</p>
                      <p class="size-medium weight-light fc-black">Exploitation ABC</p>
                      <p class="size-small weight-light fc-brownish-grey-primary">Identifiant 12345</p>
                  </div>
              </div>
              <div class="loginScreen-accountSelect">
                  <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
                  <p class="size-medium weight-medium fc-secondary-primary">Sélectionner</p>
              </div>
          </li>
      </ul>
      <div class="loginScreen-addAccounts">
          <i class="icon-short-plus fc-squash-primary mr-10"></i>
          <p class="size-medium weight-medium fc-secondary-primary">Ajouter un compte</p>
      </div>
      <div class="loginScreen-register">
        <img src="images/SVG/hello.svg" class="picture">
        <div class="loginScreen-registerText">
          <p class="weight-medium size-medium fc-black">Découvrez le Le Picard, premier négociant privé des Hauts-de-France</p>
          <a href="" class="loginScreen-createAccount">
            <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
            <p class="size-medium weight-medium fc-secondary-primary">Créez votre compte</p>
          </a>
        </div>
      </div>
  </div>
```
*/
/*doc
---
title: 2. Login step 2
name: 2. Login step 2
category: Login Forms
---
```html_example

<div class="loginScreen-accountsContainer mt-15">
    <a href="#" class="loginScreen-accountsContainer-header">
        <i class="fc-squash-primary icon-short-arrow-prev mr-10"></i>
        <p class="size-medium weight-medium fc-secondary-primary">Retour à la liste de mes comptes</p>
    </a>
    <ul class="loginScreen-accounts">
        <li class="loginScreen-account">
            <div class="loginScreen-accountInfos">
                <span class="loginScreen-accountAvatar red">LD</span>
                <div class="loginScreen-accountCredentials">
                    <p class="size-medium weight-medium fc-black">Lou Duret</p>
                    <p class="size-medium weight-light fc-black">Exploitation ABC</p>
                    <p class="size-small weight-light fc-brownish-grey-primary">Identifiant 12345</p>
                </div>
            </div>
            <div class="loginScreen-accountSelect">
                <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
                <p class="size-medium weight-medium fc-secondary-primary">Sélectionner</p>
            </div>
        </li>
    </ul>
    <div class="loginScreen-loginForm p-25">
        <p class='size-medium weight-medium mb-10'>Saisissez votre mot de passe</p>
        <div class="containerFormsCarreInput mb-10">
            <input name="input" id="input" type="password" placeholder="Mot de passe" class='formsCarre--icon' />
            <label for="input" class="icon icon-short-lock"></label>
        </div>
        <a href="#" class="weight-medium size-small fc-secondary-primary">Mot de passe oublié ?</a>
        <div class='containerBtnCarre-center bg-white  mt-25'>
            <button class='btnCarre btnCarre-primary'>Me connecter</button>
        </div>
    </div>
    <div class="loginScreen-register">
        <img src="images/SVG/hello.svg" class="picture">
        <div class="loginScreen-registerText">
        <p class="weight-medium size-medium fc-black">Découvrez le Le Picard, premier négociant privé des Hauts-de-France</p>
        <a href="" class="loginScreen-createAccount">
            <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
            <p class="size-medium weight-medium fc-secondary-primary">Créez votre compte</p>
        </a>
        </div>
    </div>
</div>


```
*/
/*doc
---
title: 3. Login forgot password
name: 3. Login forgot password
category: Login Forms
---
```html_example
  <div class="loginScreen-accountsContainer mt-15">
      <a href="#" class="loginScreen-accountsContainer-header">
          <i class="fc-squash-primary icon-short-arrow-prev mr-10"></i>
          <p class="size-medium weight-medium fc-secondary-primary">Retour à la liste de mes comptes</p>
      </a>
      <div class="loginScreen-loginForm p-25">
          <p class='size-medium weight-medium mb-10'>Saisissez votre identifiant</p>
          <div class="containerFormsCarreInput mb-10">
              <input name="input" id="input" type="password" placeholder="Mot de passe" class='formsCarre--icon' />
              <label for="input" class="icon icon-short-lock"></label>
          </div>
          <p class='size-medium weight-medium mb-10'>Votre adresse email</p>
          <div class="containerFormsCarreInput mb-10">
              <input name="input" id="input" type="email" placeholder="prénom@monmail.fr" class='formsCarre--icon' />
              <label for="input" class="icon icon-short-mail"></label>
          </div>                    
          <div class='containerBtnCarre-center bg-white  mt-25'>
              <button class='btnCarre btnCarre-primary'>Confirmer</button>
          </div>
      </div>
      <div class="loginScreen-register">
          <img src="images/SVG/hello.svg" class="picture">
          <div class="loginScreen-registerText">
          <p class="weight-medium size-medium fc-black">Découvrez le Le Picard, premier négociant privé des Hauts-de-France</p>
          <a href="" class="loginScreen-createAccount">
              <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
              <p class="size-medium weight-medium fc-secondary-primary">Créez votre compte</p>
          </a>
          </div>
      </div>
  </div>
```
*/
/*doc
---
title: 4. Login password error
name: 4. Login password error
category: Login Forms
---
```html_example

<div class="loginScreen-accountsContainer mt-15">
  <a href="#" class="loginScreen-accountsContainer-header">
      <i class="fc-squash-primary icon-short-arrow-prev mr-10"></i>
      <p class="size-medium weight-medium fc-secondary-primary">Retour à la liste de mes comptes</p>
  </a>
  <ul class="loginScreen-accounts">
      <li class="loginScreen-account">
          <div class="loginScreen-accountInfos">
              <span class="loginScreen-accountAvatar red">LD</span>
              <div class="loginScreen-accountCredentials">
                  <p class="size-medium weight-medium fc-black">Lou Duret</p>
                  <p class="size-medium weight-light fc-black">Exploitation ABC</p>
                  <p class="size-small weight-light fc-brownish-grey-primary">Identifiant 12345</p>
              </div>
          </div>
          <div class="loginScreen-accountSelect">
              <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
              <p class="size-medium weight-medium fc-secondary-primary">Sélectionner</p>
          </div>
      </li>
  </ul>
  <div class="loginScreen-loginForm p-25">
      <p class='size-medium weight-medium mb-10'>Saisissez votre mot de passe</p>
      <div class="containerFormsCarreInput mb-10">
          <input name="input" id="input" type="password" placeholder="Mot de passe" class='formsCarre--icon error' />
          <label for="input" class="icon icon-short-lock"></label>
      </div>
      <span class="alert error mb-10">
          <i class="icon-short-warning mr-10"></i>
          <p class="size-tiny weight-medium">Votre mot de passe n'est pas correct.</p>
      </span>
      <a href="#" class="weight-medium size-small fc-secondary-primary">Mot de passe oublié ?</a>
      <div class='containerBtnCarre-center bg-white  mt-25'>
          <button class='btnCarre btnCarre-primary'>Me connecter</button>
      </div>
  </div>
  <div class="loginScreen-register">
      <img src="images/SVG/hello.svg" class="picture">
      <div class="loginScreen-registerText">
      <p class="weight-medium size-medium fc-black">Découvrez le Le Picard, premier négociant privé des Hauts-de-France</p>
      <a href="" class="loginScreen-createAccount">
          <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
          <p class="size-medium weight-medium fc-secondary-primary">Créez votre compte</p>
      </a>
      </div>
  </div>
</div>
```
*/
/*doc
---
title: 5. Login add account
name: 5. Login add account
category: Login Forms
---
```html_example
  <div class="loginScreen-accountsContainer mt-15">
      <a href="#" class="loginScreen-accountsContainer-header">
          <i class="fc-squash-primary icon-short-arrow-prev mr-10"></i>
          <p class="size-medium weight-medium fc-secondary-primary">Retour à la liste de mes comptes</p>
      </a>
      <div class="loginScreen-loginForm p-25">
          <p class='size-medium weight-medium mb-10'>Saisissez votre identifiant</p>
          <div class="containerFormsCarreInput mb-20">
              <input name="input" id="input" type="password" placeholder="Mot de passe" class='formsCarre--icon' />
              <label for="input" class="icon icon-short-lock"></label>
          </div>
          <p class='size-medium weight-medium mb-10'>Saisissez votre mot de passe</p>
          <div class="containerFormsCarreInput mb-10">
              <input name="input" id="input" type="email" placeholder="Mot de passe" class='formsCarre--icon' />
              <label for="input" class="icon icon-short-mail"></label>
          </div>   
          <span class="alert ok mb-10">
            <p class="size-tiny weight-medium">Le mot de passe doit être composé de 8 caractères minimum (Chiffre, Majuscule, Minuscule)</p>
          </span>                 
          <div class='containerBtnCarre-center bg-white  mt-25'>
              <button class='btnCarre btnCarre-primary'>Confirmer</button>
          </div>
      </div>
      <div class="loginScreen-register">
          <img src="images/SVG/hello.svg" class="picture">
          <div class="loginScreen-registerText">
          <p class="weight-medium size-medium fc-black">Découvrez le Le Picard, premier négociant privé des Hauts-de-France</p>
          <a href="" class="loginScreen-createAccount">
              <i class="fc-squash-primary icon-short-arrow-next mr-10"></i>
              <p class="size-medium weight-medium fc-secondary-primary">Créez votre compte</p>
          </a>
          </div>
      </div>
  </div>
```
*/
.loginScreen {
  &-logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin: 0 0 10px 0;
  }

  &-accountsContainer {
    width: 100%;
    max-width: 435px;
    border-radius: 4px;
    background-color: $white;
    overflow: hidden;

    &-header {
      padding: 15px;
      border-bottom: 1px solid map-get(map-get($colors, 'grey-2'), 'primary');

      &:hover {
        background-color: map-get(map-get($colors, 'pale-grey'), 'primary');
      }
    }

    @media (max-width: 768px) {
      border-radius: 0;
    }
  }

  &-accounts {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-addAccounts {
    padding: 16px 0;
    background-color: rgba(129, 179, 76, 0.1);
    border-bottom: 1px solid map-get(map-get($colors, 'grey-2'), 'primary');

    &:hover {
      background-color: rgba(129, 179, 76, 0.2);
    }
  }

  &-register {
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 25px;
    background-color: map_get(map_get($colors, 'pale-grey'), 'primary');
    border-top: 1px solid map-get(map-get($colors, 'grey-2'), 'primary');

    img {
      height: 65px;
      display: block;
      flex: 0 1 auto;
    }
  }

  &-registerText {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 15px;
  }

  &-createAccount {
    margin-top: 15px;
  }

  &-loginForm {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
