@import '~assets/sass/utils/helpers/index';
.AccountingView {
  flex: 1 1 0;
  color: theme('colors.globalFont');

  &-recap {
    font-weight: $bold;

    @screen lg {
      padding: 2.6rem 1.8rem;
    }

    &-title {
      font-size: $small;
      border-bottom: 1px solid theme('colors.grey.300');
      padding-bottom: 16px;

      @screen lg {
        font-size: 16px;
      }
    }

    &-balance {
      font-size: 11px;
      margin-top: 16px;

      @screen lg {
        font-size: 16px;
        font-weight: $semibold;
        display: flex;
        margin-right: auto;
        margin-top: 18px;
      }

      div {
        display: flex;
        justify-content: space-between;

        @screen lg {
          margin-right: auto;
          gap: 30px;
        }

        button {
          margin-left: 3px;

          @screen lg {
            margin-left: 10px;
          }
        }

        div {
          align-items: center;

          @screen lg {
            margin-right: auto;
            gap: 0;
          }
        }
      }

      &-amount {
        margin-bottom: 18px;

        @screen lg {
          margin-bottom: 0;
        }
      }
    }
  }

  .AccountingViewFilters {
    margin-bottom: 2.0rem;
    width: 100%;

    &-container {
      margin-bottom: 1.6rem;

      &-btns {
        display: flex;
        justify-content: space-between;
        gap: 2.0rem;
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;

        @screen lg {
          margin-bottom: 3rem;
        }

        &-btnFilter {
          display: flex;
          align-items: center;

          @screen lg {
            display: none;
          }

          img {
            margin-inline-end: 0.6rem;
          }
        }

        &-datePicker {
          display: none;

          @screen lg {
            display: flex;
            gap: 16px;
            align-items: center;
            font-size: 1.3rem;
            background-color: $white;
            border: 1px solid;
            @apply border-grey-400;
            border-radius: 4px;
            padding: 10px 18px;
            width: 630px;
            justify-content: space-between;
            overflow: hidden;
            white-space: nowrap;
          }

          .ant-picker {
            padding: 0;

            &-input > input {
              color: theme('colors.green.100');
              font-size: 1.3rem !important;

            }

            .ant-picker-active-bar {
              background: theme('colors.green.100');
            }
          }
        }

        & .lpa-input {
          & input {
            height: 4.0rem;
            display: none;
            font-size: 13px;

            @screen lg {
              display: block;
            }
          }

          & svg {
            display: none;

            @screen lg {
              display: block;
            }
          }
        }
      }

      &-search {
        display: block;
        margin-bottom: 1.6rem;

        @screen lg {
          display: none;
        }
      }

      &-filter {

        &-select {
          display: none;

          @screen lg {
            display: block;
          }
        }

        @screen lg {
          display: flex;
          gap: 20px;
        }

        &-tags {
          display: flex;

          @screen lg {
            width: auto;
          }
        }
      }
    }
  }

  &-RadioGroup {
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 12px;
      font-weight: 700;
      line-height: 17px;
      color: theme('colors.green.100');
      margin-bottom: 1.4rem;
    }
  }

  h4 {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.02em;
    margin-bottom: 1.4rem;
    color: theme('colors.green.100');
  }
}

// For responsive purpose -> remove the second calendar (date picker) on mobile view
@media (max-width: $maxSm) {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-panel:first-child,
  .ant-picker-date-panel,
  .ant-picker-header-next-btn,
  .ant-picker-panel:first-child,
  .ant-picker-date-panel,
  .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }
}
