@import '~assets/sass/utils/helpers/index';
.BulletinsSanteDuVegetal {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url('/img/bsv_bg.jpg');
  background-size: cover;

  @media (max-width: 834px) {
    background-image: none;
  }

  &-imageContainer {
    height: 167px;
  }

  &-image {
    object-fit: cover;
    width: 100%;
    height: calc(100% + 50px);
  }


  &-wrapper {
    display: flex;
    align-items: center;
    margin-block-end: 30px;

    @media (min-width: 834px) {
      height: 100%;
      margin-block-end: 0;
    }
  }

  &-grid {
    padding-inline: 16px;

    @media (min-width: 834px) {
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      padding: 30px;
    }

    &-card {
      border-radius: 4px;
      border: 1px solid theme('colors.grey.300');
      background: #fff;
      box-shadow: 0px 0px 24px 0px rgba(8, 36, 39, 0.15);
      padding-block: 22px;
      padding-block-end: 30px;
      padding-inline: 14px;

      @media (min-width: 834px) and (max-width: 1440px) {
        grid-column-start: 3;
        grid-column-end: span 4;
      }
      @media (min-width: 1441px) {
        grid-column-start: 4;
        grid-column-end: span 3;
      }

      @media (min-width: 834px) {
        padding-block: 60px;
        padding-inline: 30px;
      }
    }
  }

  &-title {
    @apply uppercase;
    color: theme('colors.globalFont');
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

.BulletinsSanteDuVegetal-link {
  @media (min-width: 768px) {
    width: 55%;
  }

  &-card {
    border-radius: 4px;
    border: 1px solid theme('colors.grey.300');
    padding-top: 12px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 15px;

    @media (min-width: 834px) {
      margin-bottom: 30px;
    }

    &:hover {
      background: theme('colors.grey.100');
    }
  }

  &-cardRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
  }
}

.BulletinsSanteDuVegetal-text {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.28px;
  color: theme('colors.globalFont');
  margin-bottom: 25px;

  &-link {
    color: theme('colors.globalFont');
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;

    @media (min-width: 834px) {
      text-transform: initial;
    }
  }
}
