.viewContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  z-index: 2;

  @media (min-width: $minXl) {
    justify-content: flex-end;
  }

  &.vertical {
    flex-direction: column;
  }

  .content {
    background-color: map-get(map-get($colors, 'pale-grey'), 'primary');
    width: 100%;
    //padding: 25px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;

    @media (min-width: $minXl) {
      width: calc(100% - 323px);
    }

    &--gradientContainer {
      background-color: transparent;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
    }

    &--darken-background {
      position: relative;
      background-color: #000000bd;

      .image {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        opacity: 0.5;
      }

      > * {
        z-index: 5;
      }

    }
  }
}

#app {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  z-index: 1;
  width: 100%;
}

.mainContainer {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  width: 100%;
  min-height: 100vh;

  &.isSidebarOpened {
    overflow-y: hidden;
  }
}

.carouselContainer {
  display: block;
  width: 100%;
  position: relative;
  max-height: 400px;

  .backgroundSlide {
    position: relative;
    height: 400px;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      left: 0;
      background: #000;
      opacity: 0.2;
    }

  }

  .textCarousel {
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 10;
    color: #FFF;
    font-size: 55px;
    transform: translateX(-50%);
    text-align: center;

    > * {
      color: #423636;
    }

    p {
      font-weight: 100;
    }
  }


  @media (max-width: 767px) {

    .textCarousel {
      left: 0;
      transform: none;
      padding: 10px;

      > p {
        font-size: 30px;

        &:last-child {
          font-size: 25px;
        }
      }
    }

  }

}
