/*

MARGIN
m-0...m-150 = margin:150px
mt-0...mt-150 = margin-top:150px
mb-0...mb-150 = margin-bottom:150px
mr-0...mr-150 = margin-right:150px
ml-0...ml-150 = margin-left:150px

PADDING
p-0...p-150 = padding:150px
pt-0...pt-150 = padding-top:150px
pb-0...pb-150 = padding-bottom:150px
pr-0...pr-150 = padding-right:150px
pl-0...pl-150 = padding-left:150px

*/

$spaceamounts: (0, 5, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130, 140, 150,200,300); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right, all); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {

    @if $side == 'all' {
      .m-#{$space} {
        @media (min-width: 769px) {
          margin: #{$space}px  !important;
        }

        &Mobile {
          @media (max-width: 768px) {
            margin: #{$space}px !important;
          }
        }
      }

      .p-#{$space} {
        @media (min-width: 769px) {
          padding: #{$space}px !important;
        }


        &Mobile {
          @media (max-width: 768px) {
            padding: #{$space}px !important;
          }
        }
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        @media (min-width: 769px) {
          margin-#{$side}: #{$space}px !important;
        }


        &Mobile {
          @media (max-width: 768px) {
            margin-#{$side}: #{$space}px !important;
          }
        }
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        @media (min-width: 769px) {
          padding-#{$side}: #{$space}px !important;
        }

        &Mobile {
          @media (max-width: 768px) {
            padding-#{$side}: #{$space}px !important;
          }
        }
      }
    }
  }
}