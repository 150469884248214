@import '~assets/sass/utils/helpers/index';
.ActivationForm {
  font-size: 13px;

  .lpa-select__control {
    --input-padding-block: 23px;
    --input-padding-inline: 18px;
  }

  .lpa-checkbox {
    span {
      font-size: 13px !important;
    }
  }

  &-cguLink {
    text-decoration: underline;
  }
}
