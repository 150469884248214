@each $category, $array in $colors {
  @each $colors, $value in $array{
    .fc-#{$category}-#{$colors}{
      color: $value!important;
    }
  }

}
.fc-white{
  color:$white;
}

.fc-black{
  color:$black;
}
