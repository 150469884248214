@import '~assets/sass/utils/helpers/index';
.Progressbar {
  --completion-ratio: 0%;
  --completion-percent: 0%;
  //
  --radius: 2px;

  height: 22px;
  border-radius: var(--radius);
  background: theme('colors.sharedGrey.100');
  position: relative;
  width: 100%;
  overflow: hidden;

  &-bar {
    transform-origin: left center;
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
    transform: scaleX(var(--completion-ratio));
    transition-duration: 0.3s;
    background: theme('colors.sharedGreen.300');
    width: 100%;
    border-radius: var(--radius);
  }

  &-textBox {
    position: absolute;
    inset-block: 0;
    inset-inline-start: var(--completion-percent);
    display: flex;
    align-items: center;
  }

  &-text {
    padding-inline: 4px;
    width: max-content;
  }

  &-external {
    color: theme('colors.sharedGreen.100');
  }

  &-internal {
    color: theme('colors.white');

    .Progressbar-text {
      transform: translateX(-100%);
    }
  }

  &-contrast {
    background: theme('colors.white');

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border: solid theme('colors.sharedGrey.300') 1px;
    }

    .Progressbar-bar {
    }
  }
}
