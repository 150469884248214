@import '~assets/sass/utils/helpers/index';
.footer {
  //position: fixed;
  z-index: 1;
  //bottom: 0;
  width: 100%;
  text-align: center;
  background: theme('colors.green.100');
  box-shadow: 0 0 8px 0 rgba(8, 36, 39, 0.7);

  ul {
    display: block;
    padding: 7px;

    li {
      display: inline-block;
      text-align: center;
      margin: 0 7px;

      a {
        display: inline-block;
        cursor: pointer;
        color: theme('colors.white');
        font-size: 12px;
        line-height: 100%;

        &:hover {
          text-decoration: underline;
        }
      }

      span.footer-separator {
        color: map-get(map-get($colors, 'secondary'), 'primary');
      }
    }
  }

  &-version {
    font-size: 12px;
    color: theme('colors.grey.300');
  }
}
