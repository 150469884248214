@import '~assets/sass/utils/helpers/index';
.fdsRow-subTitle {
    color: theme('colors.grey.500');
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.26px;
}

.fdsRow-date {
    color: theme('colors.globalFont');
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.26px;
    margin-top:8px;
}

.fdsRow-Name {
    color: theme('colors.globalFont');
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
}

.line {
    width: 100%;
    height: 1px;
    background-color: theme('colors.grey.300');
    margin-top: 15px;
    margin-bottom: 18px;
}
