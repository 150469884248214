@import '~assets/sass/utils/helpers/index';
.QuotationsTableDesktop {
  @apply inline-flex shadow-lg rounded relative max-w-full;

  &-legend {
    @apply pt-8 px-4 text-white bg-green-100;
    width: 300px;
  }

  &-header {
    height: 104px;
  }

  &-headerCell {
    @apply w-full flex justify-center items-center h-14 px-2;
    border-block: solid theme('colors.grey.300') 1px !important;
  }

  &-yearColumn {
    border-inline-end: solid theme('colors.grey.450') 1px;

    &:last-child {
      border: 0;
    }
  }

  &-monthColumn {
    @apply grid grid-cols-1;
    min-width: 100px;
    max-width: 110px;
    border-inline: solid theme('colors.grey.300') 1px;

    &:first-child {
      border-inline-start: 0;
    }

    &:last-child {
      border-inline-end: 0;
    }
  }

  &-cellColumn {
    @apply w-full font-medium text-gray-800 bg-white;
  }

  &-cell {
    @apply flex flex-col justify-center text-green-100 h-14 bg-white even:bg-grey-100 items-center px-2;
  }
}
