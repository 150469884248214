@import '~assets/sass/utils/helpers/index';
@import '~assets/sass/utils/styleSheetVariables';

.headerNav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 17px 16px;

  @media (min-width: $minMd) {
    justify-content: space-between;
  }

  @media (min-width: $minXl) {
    padding: 25px 30px 30px 50px;
  }

  &-burgerMenu {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (min-width: $minXl) {
      display: none;
    }

    .bar-1,
    .bar-2,
    .bar-3 {
      width: 20px;
      height: 1.5px;
      border-radius: 0.75px;
      background-color: theme('colors.whiteFont');
    }

    &.opened {
      %bar {
        position: absolute;
        background-color: theme('colors.green.100');
      }

      .burgerContainer {
        gap: unset;
        transform: translateX(-5px);

        .bar-1 {
          opacity: 0;
        }

        .bar-2 {
          transform: rotate(45deg);
          width: 30px;
        }

        .bar-3 {
          transform: rotate(-45deg);
          width: 30px;
        }
      }
    }
  }

  &-logoContainer {
    flex: 1;
    height: 34px;
    display: flex;
    justify-content: center;

    @media (min-width: $minMd) {
      flex: unset;
      margin-left: 30px;
    }

    @media (min-width: $minXl) {
      margin-left: 0;
    }

    .logo {
      display: block;
      width: auto;

      img {
        display: block;
        height: 50px;
      }
    }
  }
}

.userNav {
  --profile-image-size: 40px;

  color: theme('colors.whiteFont');

  @media (min-width: $minSm) {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background-color: theme('colors.green.300');
    padding: 2px;
    border-radius: 20px;
    font-size: $paragraphe_level1_desktop_size;
    color: theme('colors.whiteFont');
  }

  @media (min-width: $minMd) {
    margin: 0 0 0 auto;
  }

  &.isUserMenuOpened {
    @media (min-width: $minLg) {
      position: relative;
    }

    ~ .userMenu-mobileMask {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100%;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      top: 0;
      left: 0;

      @media (min-width: $minLg) {
        display: none;
      }
    }
  }

  &-name {
    @screen lg {
      display: flex;
      min-width: 350px;
      align-items: center;
      padding-right: 20px;
    }
  }

  &-completeName {
    span {
      color: #4f977e;
    }
  }

  &-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: theme('colors.grey.400');
    background-color: theme('colors.green.000');
    padding: 14px 16px;
    font-size: 12px;
    font-weight: 400;

    span {
      color: theme('colors.globalFont');
    }

    @media (min-width: $minSm) {
      display: none;
    }
  }
}

.userNavOpener {
  width: 9px;
  height: 9px;
  clip-path: polygon(50% 75%, 0 25%, 100% 25%);
  background-color: #fff;

  &.isDesktop {
    position: absolute;
    right: 5px;
  }
}

.userMenu {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: theme('colors.green.300');
  border-radius: 50%;

  @media (min-width: $minSm) {
    display: flex;
  }

  @screen lg {
    border-radius: 20px;
  }

  .userNav-name > .userNavOpener {
    display: none;
    margin-inline-start: auto;

    @screen lg {
      display: block;
    }
  }

  .userMenuPortrait {
    background-color: theme('colors.userMenuPortrait');
    border: 1px solid transparent;

    .userNavOpener {
      @screen lg {
        display: none;
      }
    }
  }
  .userMenuPreview {
    @screen sm {
      display: none;
    }

    @screen lg {
      display: flex;
    }
  }
}

.userMenuDropdown {
  display: none;
  position: absolute;
  top: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 288px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  @extend %animateAll;
  z-index: 100;
  background: #fff;
  border-radius: 8px;
  border: 1px solid theme('colors.grey.300');
  color: theme('colors.globalFont');

  @media (min-width: $minLg) {
    width: 100%;
    max-width: 100%;
    top: -9px;
    left: -1px;
    transform: unset;
  }

  &.isUserMenuOpened {
    display: flex;
  }

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid theme('colors.grey.300');
    }

    &:not(.userMenuDropdown-name) {
      font-size: $h4_size;
      font-weight: $semibold;
    }

    i {
      margin-right: 10px;
    }
  }

  .userMenuPreview-account {
    display: flex;
    flex-direction: column;

    span {
      display: none;
      @screen lg {
        display: inline;
      }
    }

    @screen lg {
      flex-direction: row;
    }
  }

  &-name {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 14px;

    @screen lg {
      padding-inline-start: 2px;
      padding-block: 10px;
    }
  }

  .userMenuPortrait {
    background-color: theme('colors.grey.300');
    border: 1px solid theme('colors.grey.100');
    color: theme('colors.green.100');
  }

  &-completeName {
    flex-direction: column;
    gap: 7px;

    @media (min-width: $minLg) {
      flex-direction: row;
      gap: 9px;
    }

    .userNav-container-completeName-name {
      color: theme('colors.globalFont');
    }

    .userNav-container-completeName-business {
      color: theme('colors.grey.600');
    }

    span {
      display: none;
      color: theme('colors.grey.300');

      @media (min-width: $minLg) {
        display: block;
      }
    }
  }

  &-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-block: 6px;
  }

  &-action {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 14px;
    padding-block: 10px;
    gap: 10px;
    color: theme('colors.globalFont');

    img {
      filter: hue-rotate(theme('colors.hueRotate'));
    }

    &:hover {
      @apply bg-grey-000;
    }
  }

  &-logOut {
    width: 100%;
    background-color: #f7f8fc;
    padding-block: 16px;
    padding-inline: 14px;
    border-radius: 0 0 8px 8px;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      filter: hue-rotate(theme('colors.hueRotate'));
    }
  }
}

.userMenuPortrait {
  width: var(--profile-image-size);
  height: var(--profile-image-size);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.userMenuPreview {
  &-account {
    margin-left: 15px;
    display: flex;
    gap: 4px;

    @screen lg {
      gap: 9px;
    }
  }
  &-name {
    font-weight: $semibold;
  }
  &-business {
    font-weight: $regular;
  }
}
