@import '~assets/sass/utils/helpers/index';
.lpa-btn {
  --btn-padding-block: 10px;
  --btn-padding-inline: 30px;

  &.small {
  }

  &.medium {
  }

  border-radius: 4px;
  min-width: 20px;
  display: flex;
  gap: 5px;
  text-align: center;
  justify-content: center;
  transition-duration: 0.3s;

  &:disabled {
    opacity: 0.8;
  }

  &.primary {
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline);
    background: theme('colors.green.100');
    color: theme('colors.white');
    outline-color: theme('colors.green.100');
    border-style: unset;

    &:not(:disabled) {
      &:hover {
        transform: scale(1.01);
        @apply lpa-shadow;
      }

      &:active,
      &:focus {
        transform: scale(1);
        color: theme('colors.grey.300');
      }
    }
  }

  &.secondary {
    padding-block: calc(var(--btn-padding-block) - 2px);
    padding-inline: calc(var(--btn-padding-inline) - 2px);
    border: 1px solid theme('colors.grey.300');
    color: theme('colors.green.100');
    background: theme('colors.white');
    outline-color: theme('colors.grey.300');

    &:not(:disabled) {
      &:hover {
        transform: scale(1.01);
        @apply shadow; // FIXME
      }

      &:active,
      &:focus {
        transform: scale(1);
        color: theme('colors.green.200');
      }
    }
  }
}
