@import '~assets/sass/utils/helpers/index';
.activationScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-header {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
  }

  .activationThanks {
    font-family: Helvetica;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #6d7278;
    width: 100%;
    height: 144px;

    p {
      width: 650px;
    }

    .connectLink {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &-body {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: flex-end;
    }
  }

  &-footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: white;

    img {
      height: 40px;
      width: auto;
      display: block;
    }

    @media (max-width: 768px) {
      position: relative;
    }
  }
}
