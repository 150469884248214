@import '~assets/sass/utils/helpers/index';
.Profile {
  .card-rowBody > .form-row {
    padding-inline: 0;
  }

  &-card {
    padding-inline: 14px;
    padding-block: 22px;

    @screen lg {
      padding-inline: 30px;
      padding-block: 30px;
    }
  }
}
