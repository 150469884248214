@import '~assets/sass/utils/helpers/index';
@import '~assets/sass/utils/styleSheetVariables';

.form-row {
  padding: 10px 24px;
  width: 100%;
}

.containerColumn {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-start;

  &.directionRow {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    .label--input {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .label--input {
    font-size: $mediumS;
    font-weight: $mediumW;
    margin-bottom: 8px;

    &--header {
      font-size: $small;
      font-weight: $regular;
      color: map_get(map_get($colors, 'brown-grey'), 'primary')
    }
  }
}

.containerFormsSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding-right: 1rem;
  flex: 1 0 auto;

  .containerDropdown {
    display: flex;
    flex-direction: row;
    align-items: center;

    select {
      font-weight: $light;
      background-color: map_get(map_get($colors, 'pale-grey'), 'primary');
      padding: 16px 12px;
      height: 48px;
      border-right: 0;
      border-top: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
      border-bottom: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
      border-left: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
      border-radius: 5px 0px 0px 5px;

      &:focus {
        color: $black;

        & + label.icon {
          color: $black;
          border-left: 0;
          border-top: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
          border-bottom: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
          border-right: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
        }
      }
    }

    label.icon {
      background-color: map_get(map_get($colors, 'pale-grey'), 'primary');

      padding: 16px 12px;
      padding-top: 13px;
      height: 48px;
      border: 0;
    }
  }

  .containerInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    input {
      border-radius: 0;
      padding: 16px 24px;
      height: 48px;
      border-left: 0;
      border-top: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
      border-bottom: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
      border-right: 0px;

      &:focus {
        color: $black;

        & + label.icon {
          color: map_get(map_get($colors, 'deep-orange'), 'primary');
          border: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
        }
      }
    }

    label.icon {
      color: map_get(map_get($colors, 'deep-orange'), 'primary');
      border-radius: 0px 5px 5px 0px;
      padding: 16px 12px;
      border: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
      height: 48px;
      cursor: pointer;
    }
  }
}

.containerFormsInput {
  position: relative;
  flex: 1 0 auto;
  width: 100%;

  label.icon.inputFocused {
    color: $black;
    border-left: 1px solid $black;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    border-right: 0;
  }

  label.icon.error {
    color: map_get(map_get($colors, 'red-STATUS'), 'primary');
    border-right: 0;
    border-color: map_get(map_get($colors, 'red-STATUS'), 'primary');
  }

  label.units.inputFocused {
    color: $black;
    border-left: 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    border-right: 1px solid $black;
  }

  label.units {
    color: $black;
    border-radius: 0 5px 5px 0;
    padding-right: 16px;
    border-left: 0;
    border-top: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-bottom: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-right: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
  }

  label.units.error {
    color: map_get(map_get($colors, 'red-STATUS'), 'primary');
    border-left: 0;
    border-color: map_get(map_get($colors, 'red-STATUS'), 'primary');
  }
}

.forms {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  border-radius: 5px;
  background-color: white;
  color: $black;
  padding: 12px 16px;
  border: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
  outline: none;
  height: 48px;
  width: 100%;
  font-size: $small;

  &--units {
    font-size: $small;
    font-weight: $light;
    order: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    border-radius: 5px 0 0 5px;
    background-color: white;
    color: $black;
    padding: 12px 16px;
    border-right: 0;
    border-top: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-bottom: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-left: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    outline: none;
    height: 48px;
    width: 100%;

    &.error {
      border-radius: 0px 5px 5px 0px;
      color: map_get(map_get($colors, 'red-STATUS'), 'primary');
      border-color: map_get(map_get($colors, 'red-STATUS'), 'primary');
    }

    &:focus {
      border-right: 0;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      border-left: 1px solid $black;
      color: $black;
    }
  }

  &--icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    border-radius: 0px 5px 5px 0px;
    background-color: white;
    color: $black;
    padding: 16px 0px;
    border-right: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-top: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-bottom: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    border-left: 0;
    outline: none;
    height: 48px;
    width: 100%;
    font-size: $small;
    font-weight: $light;

    &::placeholder {
      color: map_get(map_get($colors, 'brownish-grey'), 'primary');
    }

    &.error {
      border-radius: 0px 5px 5px 0px;
      color: map_get(map_get($colors, 'red-STATUS'), 'primary');
      border-color: map_get(map_get($colors, 'red-STATUS'), 'primary');

      & + label.icon {
        color: map_get(map_get($colors, 'red-STATUS'), 'primary');
        border-right: 0;
        border-color: map_get(map_get($colors, 'red-STATUS'), 'primary');
      }

      &::placeholder {
        color: map_get(map_get($colors, 'red-STATUS'), 'primary');
      }
    }

    &:focus {
      border-right: 1px solid $black;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      border-left: 0;
      color: $black;

      & + label.icon {
        color: $black;
        border-left: 1px solid $black;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        border-right: 0;
      }
    }
  }

  &-numbers {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    border-radius: 5px;
    background-color: white;
    color: $black;
    padding: 16px 24px;
    border: 1px solid map_get(map_get($colors, 'grey-2'), 'primary');
    outline: none;
    height: 48px;
    width: 100%;
    padding-right: 40px;
    -moz-appearance: textfield;
    font-size: $small;
    font-weight: $light;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &Arrows {
      position: absolute;
      height: 48px;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      border-radius: 0 4px 4px 0;
      border: 1px solid map-get(map-get($colors, 'grey-2'), 'primary');
    }

    &Up,
    &Down {
      width: 100%;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover,
      &:focus {
        color: map-get(map-get($colors, 'red-STATUS'), 'primary');
        background-color: map-get(map-get($colors, 'pale-grey'), 'primary');
      }
    }
  }

  &::placeholder {
    color: map_get(map_get($colors, 'brownish-grey'), 'primary');
  }

  &.error {
    border-radius: 5px;
    border-color: map_get(map_get($colors, 'red-STATUS'), 'primary');
    color: map_get(map_get($colors, 'red-STATUS'), 'primary');
  }

  &:focus,
  &.simulateFocus {
    border: 1px solid $black;
    color: $black;
  }
}

.dragNdropInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid map-get(map-get($colors, 'primary'), 'primary');
  padding: 30px;
  cursor: pointer;

  text-transform: uppercase;

  color: white;
  background-color: theme('colors.greenLepicard');

  &-icon {
    flex: 0 1 auto;

    img {
      display: block;
    }
  }

  &-content {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &-cta {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    @extend %animatedForward;
  }

  &:hover {
    box-shadow: 0px 4px 0 rgba(0, 0, 0, 0.3);
    font-weight: 500;
  }
}

.heading {
  align-self: flex-start;
  margin-bottom: 16px;
  font-weight: $blackW;
  font-size: $veryLarge;
}

.disabledForm {
  .containerFormsInput {
    pointer-events: none;
    background-color: rgb(242, 242, 242);


    label {
      border: none;
      color: $black;
    }

    .forms-numbers {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
    }

    .forms-numbersArrows {
      display: none;
    }

    input {
      background-color: rgb(242, 242, 242);

      color: $black;
      font-weight: $mediumW;
      font-size: $mediumS;
    }

    .dragNdropInput {
      display: none;
    }
  }
}

.cgu-link {
  display: block;
  padding: 10px;
  text-decoration: underline;
  color: blue;
}
