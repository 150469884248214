/* CK EDITOR STYLES */
.ckEdited {
  font-size: $small;

  * {
    line-height: 2.4rem !important;
  }

  h1 {
    flex: 0 1 auto;
    font-size: $veryLarge;
    font-weight: $blackW;
    line-height: 1.33;
    color: $black;
    margin-bottom: 15px;
  }

  h2 {
    color: map-get(map-get($colors, 'primary'), 'primary');
    font-weight: $mediumW;
    font-size: $large;
    margin-bottom: 25px;
    line-height: 1.6;
  }

  h3 {
    flex: 0 1 auto;
    font-size: $mediumS;
    font-weight: $mediumW;
    line-height: 1.6;
    color: $black;
    margin-bottom: 8px;
  }

  h4 {
    flex: 0 1 auto;
    font-size: $small;
    font-weight: $mediumW;
    line-height: 1.6;
    color: $black;
    margin-bottom: 8px;
  }

  p {
    flex: 0 1 auto;
    font-size: $small;
    font-weight: $light;
    line-height: 2;
    color: $black;
    margin-bottom: 25px;
  }

  figure {
    flex: 0 1 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  img {
    flex: 0 1 auto;
    width: 100%;
    height: auto;
    display: block;

    & + figcaption {
      flex: 0 1 auto;
      font-size: $small;
      color: map-get(map-get($colors, 'brownish-grey'), 'primary');
      font-weight: $light;
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }

  a {
    font-weight: $mediumW;
    text-decoration: underline;
  }

  blockquote {
    background-color: map-get(map-get($colors, 'light-mustard'), 'dark');
    padding: 50px 35px;
    width: 100%;
    border-radius: 0em;
    font-style: italic;
    margin-bottom: 30px;

    p {
      font-weight: $mediumW;
      color: $white;
      line-height: 1.6;

      &:not(.ckEdited--author) {
        margin-bottom: 15px;
      }
    }

    .ckEdited--author {
      text-align: right;
      font-weight: $light;
      font-style: normal;
      font-size: $small;
      margin-bottom: 0;
    }
  }


  strong {
    font-weight: $black !important;
  }

  i {
    font-style: italic !important;

    &[class^=icon-] {
      font-style: normal !important;
    }
  }

  u, ins {
    text-decoration: underline !important;
  }

  ul {
    list-style: inside !important;
    line-height: 2.4rem;
    font-weight: $light;
    font-size: $small;

    li {
      & > ul {
        padding-left: 20px;
        list-style: inside circle !important;
        line-height: 2.4rem;
      }
    }
  }

  p {
    margin-bottom: 10px;
  }

  a:not([class^=castorBtn-]) {
    text-decoration: underline !important;
  }
}
