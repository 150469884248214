@import '~assets/sass/utils/helpers/index';
.areasContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  > div {
    flex: 0;
    flex-basis: 50%;
    padding: 0 20px;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: theme('colors.grey.300');
  margin-top: 30px;
  margin-bottom: 30px;
}

.durabilityTitle {
  color: theme('colors.globalFont');
  font-size: 12px;
  line-height: normal;
  font-weight: theme('fontWeight.700');
  margin-bottom: 10px;

  @media (min-width: 834px) {
    font-size: 22px;
    font-weight: theme('fontWeight.700');
  }
}

.durabilityParagraph {
  color: theme('colors.globalFont');
  line-height: 1.2em;
}

.durabilityMention {
  color: theme('colors.grey.600');
  font-size: 10px;
  font-style: italic;

  @media (min-width: 834px) {
    font-size: 12px;
  }
}

.durabilitySubTitle {
  color: theme('colors.globalFont');
  font-size: 10px;
  margin-bottom: 30px;

  @media (min-width: 834px) {
    font-size: 14px;
  }
}

.durabilityCampaign {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.durabilityMandatory {
  color: theme('colors.grey.600');
  font-weight: theme('fontWeight.700');
  font-size: 10px;

  @media (min-width: 834px) {
    font-size: 12px;
    font-style: italic;
    font-weight: theme('fontWeight.500');
    line-height: 16px;
    letter-spacing: 0.24px;
  }
}

.durability_threeItemsRow {

  display: flex;
  margin-bottom: 16px;

  @media (max-width: 999px) {
    @apply
    justify-between;
  }
  @media (min-width: 1000px) {
    @apply
    w-1/3
    mb-0
  }
}


.durability_twoItemsRow {
  @apply
  md:w-1/2;

  flex-direction: column;
  display: flex;
}

.durability_twoItemsRowTwixed {
  width: calc(50% - 8px);
}

.durability_ValueName {
  color: theme('colors.grey.600');
  font-size: 15px;
  line-height: 1em;
  font-weight: 500;
  margin-right: 8px;
}

.durability_Value {
  flex: 1;
  align-items: center;

  color: theme('colors.globalFont');
  font-size: 15px;
  line-height: 1em;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 1000px) {
    text-align: left;
  }
}

.durability_myProfile {
  color: theme('colors.globalFont');
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-decoration: underline;
}

.campagnFilter {
  @apply
  flex
  min-w-[217px]
  text-green-100;

  @media (max-width: 379px) {
    min-width: 100px !important;
  }
  @media (min-width: 380px) and (max-width: 1440px) {
    min-width: 175px !important;
  }
}

.harvestInformation {
  @apply
  flex
  flex-col
  my-8;
  @media (min-width: 1000px) {
    @apply
    flex-row;
  }
}

.errorAlert {
  color: theme('colors.red');
  background-color: white !important;
  font-size: 12px;
  font-style: italic;

  @media (max-width: 834px) {
    font-size: 11px;
  }
}
