@import '~assets/sass/utils/helpers/index';
@mixin min($minBreakpoint) {
  @media screen and (min-width: $minBreakpoint) {
    @content;
  }
}

@mixin max($maxBreakpoint) {
  @media screen and (max-width: $maxBreakpoint) {
    @content;
  }
}

$min-mobile: 321px;
$max-mobile: 767px;
$min-tablet: 768px;
$max-tablet: 991px;
$min-laptop: 992px;
$max-laptop: 1199px;
$min-desktop: 1200px;


.SpineBubble {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;

  min-height: 100%;
  max-height: 100%;
  background-color: theme('colors.green.200');

  @include min($min-tablet) {
    align-items: center;
  }

  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    background-color: white;
    margin-left: auto;

    @include max($max-mobile) {
      position: absolute;
      bottom: 0;

      .SpineBubble-content {
        max-height: calc(100vh - 130px);
      }
    }

    @include min($min-tablet) {
      width: auto;
      min-width: min(75vw, 450px);
      max-width: 450px;
      margin-right: 10vw;
      border-radius: 4px;

      &.isFullPage {
        width: 500px;
        max-width: 50vw;
        height: 100%;
        margin-right: 0;

        .SpineBubble-content {
          max-height: initial !important;
        }
      }
    }

  }

  &-content {
    max-height: 50vh;
    flex-grow: 1;
    overflow-y: auto;
  }


  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 30px 15px 30px 15px;
    background-color: #F7F8FC;
    border-top: 1px solid theme('colors.grey.300');
  }
}

.BubbleContent {
  padding: 30px 15px 30px 15px;
  color: theme('colors.green.100');

  @include min($min-tablet) {
    padding: 0px 60px 60px;
  }

  &-header {
    text-align: center;
    margin-bottom: 30px;
  }

  &-body {
    > * {
      margin-bottom: 0;
    }

    .form-row {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.BubbleHeader {
  position: relative;
  justify-content: center;

  &.isDesktop {
    display: none;
  }

  &.isMobile {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 30px;
  }

  @include min($min-tablet) {
    padding: 60px 110px;

    &.isDesktop {
      display: flex;
    }
    &.isMobile {
      display: none;
    }

  }

  &-link {
  }

  &-back {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-logo {
    width: 290px;
    max-width: 100%;
    height: auto;

    @include max($max-mobile) {
      max-width: 60vw;
    }
  }
}


.Login {
  &-title {
    color: theme('colors.green.100');
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
      margin-bottom: 22px;
    }
  }

  &-background {
    max-width: 50vw;
    margin-left: auto;
    margin-right: 40px;

    @include max($max-mobile) {
      max-width: initial;
      margin-left: initial;
      margin-right: initial;

      display: flex;
      align-items: center;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
