@import '~assets/sass/utils/helpers/index';
.lpa-input {
  --input-padding-block: 23px;
  --input-padding-inline: 18px;
  --icon-size: 20px;
  --icon-gap: 5px;

  position: relative;

  &.hasFloatingLabel {
    margin-block-start: 13px; // Compensate the label
  }

  & > * {
    font-size: 12px;
    line-height: 14px;
    font-weight: theme('fontWeight.500');
  }

  input,
  textarea {
    border-radius: 4px;
    border: 1px solid theme('colors.grey.400');
    padding-inline: var(--input-padding-inline);
    padding-block: var(--input-padding-block);
    background: theme('colors.white');
    min-height: 41px;
    outline-style: none;
    width: 100%;

    &:active,
    &:focus {
      border-color: theme('colors.grey.600');
    }

    &:disabled {
      color: #CCC;
    }
  }

  textarea {
    resize: vertical;
    max-height: 500px;
  }

  label {
    color: theme('colors.grey.500');
    background: theme('colors.white');
    position: absolute;
    inset-block-start: var(--input-padding-block);
    inset-inline-start: var(--input-padding-inline);
    transition-duration: 0.2s;
    padding-inline: 7px;
    pointer-events: none;
    transform-origin: top left;
    transition-timing-function: ease-out;
  }

  input,
  textarea {
    &::placeholder {
      color: theme('colors.grey.500');
    }

    &:active,
    &:focus,
    &:not(:placeholder-shown) {
      & + label {
        transform: translateY(calc((var(--input-padding-block) + (18px / 2)) * -1)) scale(0.8);
        color: theme('colors.grey.500');
      }
    }
  }

  &.hasBeforeIcon {
    input,
    textarea {
      padding-inline-start: calc(var(--input-padding-inline) + var(--icon-size) + var(--icon-gap));
    }
  }
  &.hasAfterIcon {
    input,
    textarea {
      padding-inline-end: calc(var(--input-padding-inline) + var(--icon-size) + var(--icon-gap));
    }
  }

  &__icon {
    position: absolute;
    inset-block: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    &__before {
      inset-inline-start: var(--input-padding-inline);
    }

    &__after {
      inset-inline-end: var(--input-padding-inline);
    }
  }
}

.isError{
  input,
  textarea {
    border-color: theme('colors.red');

    & + label {
      color: theme('colors.red') !important;
    }
  }
}
