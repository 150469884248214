@import '~assets/sass/utils/helpers/index';
.table-cardHeader {
  border-bottom: solid 1px #e6e6e6;
}

.tableContainer {
  table-layout: auto;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .table-header {
    width: 100%;
    max-width: 100%;
    min-width: 100%;

    .headerRow {
      max-width: 100%;
      min-width: 100%;
      border-bottom: solid 1px #e6e6e6;

      .headerCell {
        font-weight: 400;
        color: #787878;
        display: flex;
        flex-grow: 1;
        padding: 1rem 2rem;
        overflow: hidden;
        justify-content: space-between;
        align-items: center;

        .sort-indicator {
          position: absolute;
          right: 0;
          margin-right: 2rem;
        }

        &.last {
          border-left: 0;
        }

        .resizer {
          position: absolute;
          top: 0;
          height: 100%;
          width: 1rem;
          right: 0;
        }
      }
    }
  }

  .bodyRow {
    width: 100% !important;
    max-width: 100%;
    display: flex;

    .cell {
      flex-grow: 1;
      padding: 2rem 2.5rem;
      overflow: hidden;
      font-weight: 100;

      &.lastRow {
        border-bottom: solid 1px #e6e6e6;
        @media screen and (max-width: 775px) {
         width: auto!important;
        }
      }
    }
  }
}

.detailsTable {
  .headerCell {
    font-weight: 500!important;
    color: #000!important;
  }
}
