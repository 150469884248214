@import '~assets/sass/utils/helpers/index';
.lpa-datepicker {
  @apply relative;

  --input-padding-block: 9px;
  --input-padding-inline: 18px;

  .react-datepicker {
    &__input-container {
      display: inline-grid;
      grid-area: 1/1/2/3;
      grid-template-columns: 0;
      border-radius: 4px;
      border: 1px solid theme('colors.grey.400');
      padding-inline: var(--input-padding-inline);
      padding-block: calc(var(--input-padding-block) - 4px);
      background: theme('colors.white');
      width: 100%;
      min-width: 100px;

      input {
        @apply text-green-100 outline-none;
        grid-area: 1 / 2;
        min-width: 2px;
        border: 0px;
        margin: 0px;
        outline: 0px;
        padding: 0px;
      }
    }

    &__day {
      margin: theme('margin.[1.5]') !important;

      &--selected {
        @apply bg-green-100;
      }

      &--keyboard-selected {
        @apply bg-grey-600;
      }
    }
  }

  &.hasFloatingLabel {
    .react-datepicker {
      &__input-container {
        padding-inline-start: calc(var(--input-padding-inline) + 30px);
      }
    }
  }

  &.isFocused {
    .react-datepicker {
      &__input-container {
        border-color: theme('colors.grey.600');
      }
    }
  }

  &-label {
    @apply absolute inset-y-0 left-0 text-green-100 flex items-center;
    padding-inline-start: var(--input-padding-inline);
    padding-block: var(--input-padding-block);
  }
}
