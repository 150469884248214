@import '~assets/sass/utils/helpers/index';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap'); // 300 font weight shouldn't be used

@import 'sass/utils/styleSheetVariables';

@import 'sass/utils/reset';
@import 'sass/utils/helpers/index';
@import 'sass/utils/main';
@import 'sass/packages/ckedited';
@import 'sass/utils/text/textlineheight';
@import 'sass/utils/text/textsize';
@import 'sass/utils/text/textweight';
@import 'sass/utils/text/textcolor';
@import 'sass/utils/toast/toast';
@import 'sass/utils/positions-display/margins';
@import 'sass/utils/positions-display/hidden';
@import 'sass/utils/positions-display/width';
@import 'sass/utils/positions-display/flex';
@import 'sass/utils/positions-display/grid';
@import 'sass/utils/list/list';

@import 'sass/utils/placeholder/animate';
@import 'sass/utils/placeholder/gridLayout';
@import 'sass/utils/display/display';
@import 'sass/utils/cards/cards';

// Manage app recurring containers
@import 'sass/utils/containers/containers';


@import 'sass/tailwind/tailwind.scss';
@import 'sass/tailwind/utils-class.scss';

@import 'sass/packages/tippy';
@import 'sass/packages/indiana-drag-scroll';

@import 'sass/Components/Card/Card';

// React datepicker
.react-datepicker-wrapper {
  width: 100%;
}
