@import '~assets/sass/utils/helpers/index';
.QuotationsQuality {
  @apply rounded shadow-lg overflow-hidden bg-white;

  &-title {
    @apply px-4 py-4 text-quotation-qualite bg-green-100 text-white;
  }

  &-container {
    @apply lg:grid lg:grid-cols-2;
  }
}

.QuotationsQualityItem {
  @apply px-3.5 py-4 gap-2.5;
  display: grid;
  grid-template-columns: 24px 1fr auto;
  align-items: flex-start;
  border: solid 1px theme('colors.grey.300');
  border-block-start: 0;

  @apply bg-white even:bg-grey-100;

  @screen lg {
    @apply even:bg-white;
    border: 0;

    &:nth-child(odd) {
      border-inline-end: solid 1px theme('colors.grey.300');
    }

    &:nth-child(4n),
    &:nth-child(4n - 1) {
      @apply bg-grey-100;
    }
  }

  &-icon {
    @apply w-6;

    img {
      filter: hue-rotate(theme('colors.hueRotate'));
    }

    @screen lg {
      grid-row: span 2;
    }
  }

  &-title {
    color: theme('colors.globalFont');
  }

  &-tax {
    @apply text-right;
    color: theme('colors.globalFont');
  }

  &-text {
    @apply text-gray-500 text-[13px];
    grid-column: span 3;

    @screen lg {
      grid-column: 2 / span 3;
    }
  }
}
