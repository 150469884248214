/*
FONT-SIZE
size-tiny = font-size:1.2rem
size-small = font-size:1.4rem
size-medium = font-size:1.6rem
size-large = font-size:2rem
size-veryLarge = font-size:2.4rem
size-Huge = font-size:3rem
 */

.size {
  &-tiny {
    font-size: $tiny;

    @media (max-width: 768px) {
      font-size: $Mtiny;
    }

    &Mobile {
      @media (max-width: 768px) {
        font-size: $Mtiny !important;
      }
    }
  }

  &-small {
    font-size: $small;

    @media (max-width: 768px) {
      font-size: $Msmall;
    }

    &Mobile {
      @media (max-width: 768px) {
        font-size: $Msmall !important;
      }
    }
  }

  &-medium {
    font-size: $mediumS;

    @media (max-width: 768px) {
      font-size: $mediumS;
    }

    &Mobile {
      @media (max-width: 768px) {
        font-size: $MmediumS !important;
      }
    }
  }

  &-large {
    font-size: $large;

    @media (max-width: 768px) {
      font-size: $large;
    }

    &Mobile {
      @media (max-width: 768px) {
        font-size: $Mlarge !important;
      }
    }
  }

  &-veryLarge {
    font-size: $veryLarge;

    @media (max-width: 768px) {
      font-size: $MveryLarge;
    }

    &Mobile {
      @media (max-width: 768px) {
        font-size: $MveryLarge !important;
      }
    }
  }
  &-huge {
    font-size: $huge;

    @media (max-width: 768px) {
      font-size: $Mhuge;
    }

    &Mobile {
      @media (max-width: 768px) {
        font-size: $Mhuge !important;
      }
    }
  }
}
