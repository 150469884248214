@import '~assets/sass/utils/helpers/index';
.AccountingTableDesktop {
  background-color: white;
  border: 1px solid theme('colors.grey.300');
  width: 100%;
  font-size: 1.3rem;

  & thead {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    text-align: left;

    tr > th {
      color: theme('colors.grey.500');
      border-bottom: 1px solid theme('colors.grey.300');
      padding: 18px;
      font-weight: $bold;
      max-width: 100%;
      min-width: 100%;
      display: table-cell !important;
      width: auto !important;

      & svg {
        margin-left: 6px;
        filter: hue-rotate(theme('colors.hueRotate'));
      }
    }
  }

  &-sortedColumn {
    &:hover {
      cursor: pointer;
    }
  }

  &-body {
    &-row {
      white-space: nowrap;
      border-bottom: 1px solid theme('colors.grey.300');
      font-weight: $semibold;
      max-width: 100%;
      min-width: 100%;
      height: 60px;

      &-date {
        color: theme('colors.grey.600');
        font-weight: $regular;
      }

      &-type {
        display: flex;

        & span {
          display: flex;
          gap: 8px;
          align-items: baseline;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        & img {
          height: 22px;
          transform: translateY(8px);
          filter: hue-rotate(theme('colors.hueRotate'));
        }

        &.blackAndWhiteIcon img {
          filter: grayscale(1);
        }
      }

      &-restToPaid {
        text-align: right !important;
      }

      & td {
        padding: 18px;
        vertical-align: bottom;
      }

      &-linkedInvoices {
        gap: 15px;
        display: flex;
        cursor: pointer;
        width: 60px;

        &-paperClip {
          position: relative;

          & svg {
            width: 16px;
            height: 16px;
          }

          &-number {
            position: absolute;
            bottom: -4px;
            right: -10px;
            background-color: theme('colors.grey.300');
            color: $black;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1rem;
          }
        }
      }
    }

    .collapsed {
      background-color: theme('colors.grey.100');
    }

    &-collapsibleRow {
      background-color: theme('colors.grey.000');
      color: theme('colors.grey.600');
      border-bottom: 1px solid theme('colors.grey.300');

      & td {
        padding: 14px;
      }

      &-type {

        span {
          display: flex;
          gap: 8px;
          align-items: baseline;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: theme('colors.green.100');
          }

          & img {
            height: 25px;
            transform: translateY(8px);
            filter: hue-rotate(theme('colors.hueRotate'));
          }
        }
      }
    }
  }
}
