@import '~assets/sass/utils/helpers/index';
@import '~assets/sass/utils/styleSheetVariables';

.label {
  &Green {
  }
}

.containerBtn {
  &-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &Radio {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 10px;

    p {
      //font-size: $small;
      margin-left: 30px;
      /*font-weight: 300;*/
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &:hover input ~ .btn-radio {
      //when hovered
      background-color: transparent;
      border: 1px solid map_get(map_get($colors, 'primary'), 'primary');
    }

    input:checked ~ .btn-radio {
      background-color: transparent;

      &:after {
      }
    }

    input:checked ~ .btn-radio:after {
      content: '';
      display: block;
      background-color: theme('colors.green.300');
      width: 17px;
      height: 17px;
      flex-shrink: 0;
      border-radius: 34px;
    }
  }
}

.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  display: inline-flex;
  cursor: pointer;
  font-weight: $mediumW;
  font-size: $mediumS;
  @extend %animateAll;
  flex-shrink: 0;

  &.details-btn {
    font-size: 2.5rem;
  }

  &-primary {
    background-color: theme('colors.greenLepicard');
    color: $white;
    border-radius: 5px;
    padding: 12px 24px;

    &:hover {
      background-color: theme('colors.green.000') !important;
    }
  }

  &-secondary {
    background-color: map_get(map_get($colors, 'grey-2'), 'primary');
    color: map_get(map_get($colors, 'brown-grey'), 'primary');
    border-radius: 5px;
    padding: 12px 24px;

    &:hover {
      color: map_get(map_get($colors, 'brown-grey'), 'dark');
      box-shadow: 0px 4px 0 rgba(0, 0, 0, 0.3);
      font-weight: $mediumW;
    }

    &:focus {
      color: map_get(map_get($colors, 'brown-grey'), 'dark');
    }
  }

  &-outline {
    border: 1px solid theme('colors.green.000');
    background-color: transparent;
    color: theme('colors.globalFont');
    border-radius: 5px;
    padding: 12px 24px;

    &:hover {
      color: theme('colors.green.300');
      box-shadow: 0px 4px 0 rgba(0, 0, 0, 0.3);
      font-weight: $mediumW;
    }

    &:focus {
      color: map_get(map_get($colors, 'primary'), 'light');
    }
    &.validated {
      background-color: map_get(map_get($colors, 'pale-grey'), 'primary');
      border: none;
    }
  }

  &-carre {
    padding: 12px 16px;
    font-size: $mediumS;
    position: relative;

    &.arrow-white {
      color: $white;
      background-color: map_get(map_get($colors, 'pale-grey'), 'primary');
    }

    &.arrow-primary {
      color: $white;
      background-color: map_get(map_get($colors, 'primary'), 'primary');
    }

    &.shop-primary {
      color: $white;
      background-color: map_get(map_get($colors, 'primary'), 'primary');
    }
    &.shop-white {
      border: 1px solid map_get(map_get($colors, 'primary'), 'primary');
      color: map_get(map_get($colors, 'primary'), 'primary');
      background-color: $white;
    }

    &.alreadyAdded {
      &:after {
        position: absolute;
        top: 5px;
        right: 5px;
        content: '\e911';
        font-family: icomoon;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background-color: map-get(map-get($colors, 'green-STATUS'), 'primary');
        color: $white;
        font-size: $tiny;
      }
    }

    &.shop-transparent {
      border: 1px solid map_get(map_get($colors, 'pale-grey'), 'primary');
      color: $white;
      background-color: transparent;
    }

    &:hover {
      color: map_get(map_get($colors, 'primary'), 'light');
      box-shadow: 0px 4px 0 rgba(0, 0, 0, 0.3);
      font-weight: $mediumW;
    }

    &:focus {
      color: map_get(map_get($colors, 'primary'), 'light');
    }
  }

  &-rounded {
    width: 40px;
    height: 40px;
    flex: 0 1 auto;
    padding: 8px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid map-get(map-get($colors, 'grey-2'), 'primary');
    color: map-get(map-get($colors, 'grey-2'), 'primary');

    &:hover,
    &.active {
      color: map-get(map-get($colors, 'primary'), 'primary');
    }
  }

  &-radio {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: transparent;
    border: 1px solid theme('colors.grey.400');
    border-radius: 50%;

    &:after {
    }
  }

  /*

      &-absoluteCenter {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        text-align: center;

        &:hover {
          transform: translateX(-50%) scale(1.05);
        }

        @media (max-width: 768px) {
          position: relative;
          transform: none;
          left: unset;
          width: max-content;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
      */
}

.puceCarmin {
  display: flex;
  align-items: center;

  &::before {
    content: ' ';
    width: 5px;
    height: 5px;
    background-color: map_get(map_get($colors, 'primary'), 'primary');
    margin-right: 8px;

    @media (max-width: 768px) {
      display: inline-block;
    }
  }

  @media (max-width: 768px) {
    display: inline;
  }
}
